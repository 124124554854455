import React from 'react'
import { FormattedMessage } from 'react-intl'

import styles from '../../styles/info-site/BrandPartnerships.module.css'

const BrandPartnerships = ({ children, title }) => {
  return (
    <div className={styles.BrandPartnerships}>
      <h2 className={styles.BrandPartnershipsSubtitle}><FormattedMessage id={title} /></h2>
      {
        children
      }
    </div>
  )
}

export default BrandPartnerships