import React from "react";
import Stage from "../../components/info-site/Stage";
import Layout from "../../layouts/en";
import Seo from "../../components/info-site/Seo";
import GetStarted from "../../components/info-site/GetStarted";
import StageContent from "../../components/info-site/StageContent";
import BrandPartnerships from "../../components/info-site/BrandPartnerships";
import SideContentWithImg from "../../components/info-site/SideContentWithImg";
import raiseCustomer from "../../data/raiseCustomer";

const RaiseCustomerLifetime = props => {
    return (
        <Layout location={props.location}><Stage
          title="raiseCustomerTitle"
          icon
          withoutMedia
        >
          <Seo titleId="raiseCustomerTitle" descriptionId="raiseCustomerDescription" />
          <StageContent
              title="raiseCustomerSideTitle"
              content="raiseCustomerSideDescription"
              ctaLink="https://rtbmedia.hubspotpagebuilder.com/book-a-demo"
              ctaText="homepageStagePrimaryCta"
              wideText
              listContent={{
                title: "raiseCustomerSideListTitle",
                items: [
                  "raiseCustomerSideListItemOne", "raiseCustomerSideListItemTwo", "raiseCustomerSideListItemThree",
                ],
              }}
            />
          </Stage>

          <BrandPartnerships title="raiseCustomerFeatures">
              {
                raiseCustomer.map(({id, ...rest}) => (
                  <SideContentWithImg
                    lightParagraph
                    key={id}
                    {...rest}
                  />
                ))
              }
            </BrandPartnerships>

          <GetStarted />
        </Layout>
    );
};

export default RaiseCustomerLifetime;