import LtvImg1 from '../assets/ltv1.jpg'
import LtvImg2 from '../assets/ltv2.jpg'
import LtvImg3 from '../assets/ltv3.png'
import LtvImg4 from '../assets/ltv4.jpg'


const raiseCustomer = [
  {
    id: "raise-customer-1",
    title: "raiseCustomerTitleOne",
    content: "raiseCustomerDescOne",
    img: LtvImg1,
  },
  {
    id: "raise-customer-2",
    title: "raiseCustomerTitleTwo",
    content: "raiseCustomerDescTwo",
    img: LtvImg2,
    reversed: true
  },
  {
    id: "raise-customer-3",
    title: "raiseCustomerTitleThree",
    content: "raiseCustomerDescThree",
    img: LtvImg3,
  },
  {
    id: "raise-customer-4",
    title: "raiseCustomerTitleFour",
    content: "raiseCustomerDescFour",
    img: LtvImg4,
    reversed: true
  },
]

export default raiseCustomer;
