import React from 'react'
import { FormattedMessage, FormattedHTMLMessage } from 'react-intl'

import Cta from '../styleguide/Cta'

import styles from '../../styles/info-site/SideContentWithImg.module.css'

const SideContentWithImg = ({ title, content, ctaText, ctaLink, reversed, img, lightParagraph }) => {
  return (
    <div className={styles.SideContentWithImg}>
      <div className={`${styles.SideContentWithImgWrapper} ${reversed ? styles.SideContentWithImgWrapper__reversed : ''}`}>
        <div className={`${styles.SideContentWithImgSide} ${styles.SideContentWithImgSide__content} ${lightParagraph ? styles.SideContentWithImgSide__lighter : ''}`}>
          <h2><FormattedMessage id={title} /></h2>
          <p><FormattedHTMLMessage id={content} /></p>
          <div className={styles.SideContentWithImgActions}>
            {
              ctaText && <Cta className={styles.SideContentWithImgCta} link={ctaLink} text={ctaText}/>
            }
          </div>
        </div>
        <div className={styles.SideContentWithImgSide}>
          <img src={img} alt="" />
        </div>
      </div>
    </div>
  )
}

export default SideContentWithImg